import { LoaderInterface } from "../../../utils/typings/Shared";



const Loader = ({ color='stroke-teal', className }: LoaderInterface) => {

    return (
        <div id="loader" className={`${className}`}>
            <svg className="block mx-auto loader" viewBox="25 25 50 50">
                <circle className={` ${color} `} r="20" cy="50" cx="50"></circle>
            </svg>
        </div>
    );
}

export default Loader